import React from "react";
import "./bigCard.css";

export default function BigCard({ title, text, time }) {
  return (
    <div className="bigCard">
      <h1>{title}</h1>
      <p>{text}</p>
      <p>{time}</p>
    </div>
  );
}
