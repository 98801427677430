import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import BigCard from "../../components/Card/BigCard/BigCard";
// import Commento from "../../components/Commento/Commento";
import EmailButton from "../../components/Button/EmailButton/EmailButton";
import Footer from "../../components/Footer/Footer";
import RippleButton from "../../components/Button/RippleButton/RippleButton";

import messageIcon from "../../assets/svg/messageIcon.svg";
import headsetIcon from "../../assets/svg/headsetIcon.svg";
import handshakeIcon from "../../assets/svg/handshakeIcon.svg";

import TechLife from "../../assets/svg/TechLife.svg";

// import blueGaussian from "../../assets/images/BlueGaussian.png";

import "./home.css";

export default function Home({ servizi }) {
  const navigate = useNavigate();
  const myRef = useRef(null);

  const scrollToRef = () => {
    myRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="homePage">
      <Navbar></Navbar>
      {/* <img src={blueGaussian} alt="Backgorund" className="bg" /> */}
      <div className="intro">
        <div className="introH1">
          <h1>ETIS Software: Portiamo il Futuro alle Tue Idee d'Impresa</h1>
          <p>
            Dal concetto alla codifica, offriamo soluzioni software che
            catalizzano il successo aziendale. Il futuro è scritto in una riga
            di codice, e quella riga inizia qui.
          </p>
        </div>
        <img src={TechLife} alt="Tech Life" className="homeImage" />
      </div>
      <RippleButton onClick={scrollToRef}>Scopri di più</RippleButton>
      <div className="cosafacciamo" ref={myRef}>
        <h1>Soluzioni Software Su Misura</h1>
        <p>
          Da start-up innovative a imprese consolidate, ETIS sviluppa software
          che è altrettanto unico quanto il tuo business. Se puoi immaginarlo,
          possiamo crearlo.
        </p>
      </div>
      <div className="serviziHome">
        {servizi.map((servizio) => (
          <BigCard
            title={servizio.title}
            text={servizio.text}
            time={servizio.time}
          ></BigCard>
        ))}
        <div className="center-center">
          <RippleButton
            className={"gotoIniziaButtonCard"}
            onClick={() => {
              navigate("Inizia");
              window.scrollTo(0, 0);
            }}
          >
            <h3>Calcola un preventivo, senza impegno</h3>
            <div>Inizia {"\u2192"}</div>
          </RippleButton>
        </div>
      </div>
      <div className="assistenza">
        <h1>Assistenza 24/7</h1>
        <p>
          La nostra dedizione al vostro successo è ininterrotta, giorno e notte.
          Oltre alla nostra dedizione all'arte del digitale, offriamo
          un'assistenza ininterrotta che supera le barriere del tempo e dello
          spazio. Siamo al vostro servizio sia in<b> chat</b> , che in
          <b> chiamata</b> , ma anche (ove richiesto) <b>di persona</b> , il
          nostro impegno è quello di fornire un supporto costante in ogni
          istante, unendoci a voi nel percorso
        </p>
        <div className="assistenzaIcon">
          <img src={messageIcon} alt="Via chat" />

          <img src={headsetIcon} alt="In chiamata" />
          <img src={handshakeIcon} alt="Dal vivo" />
        </div>
      </div>
      <div className="why">
        <h1>I Vantaggi di ETIS Software</h1>
        <div className="whyOptions">
          <div
            className="whyOption"
            onClick={() => console.log("sposta view su servizi")}
          >
            <h2>Approccio Personalizzato</h2>
            <p>
              Ogni azienda è diversa. Le nostre soluzioni sono calibrate sulle
              tue specifiche esigenze.
            </p>
          </div>
          <div className="divider"></div>
          <div
            className="whyOption"
            onClick={() => console.log("sposta view su assistenza")}
          >
            <h2>Supporto Costante</h2>
            <p>
              Non ti lasciamo mai solo. Il nostro team di assistenza è sempre a
              tua disposizione.
            </p>
          </div>
          <div className="divider"></div>
          <div
            className="whyOption"
            onClick={() => console.log("sposta view su ")}
          >
            <h2>Innovazione Continua</h2>
            <p>
              Non ci accontentiamo dello status quo. Siamo sempre in cerca del
              prossimo passo evolutivo.
            </p>
          </div>
        </div>
      </div>

      <div className="preventivo">
        <div
          className="gotoIniziaButton"
          onClick={() => {
            navigate("Inizia");
            window.scrollTo(0, 0);
          }}
        >
          Stima costi
        </div>
        {/* <div className="miniText">Trasparenza totale, nessuna sorpresa. </div> */}
      </div>
      <div className="midText">Oppure</div>
      <EmailButton text={"Inviaci un' Email"}></EmailButton>
      <Footer></Footer>
    </div>
  );
}
