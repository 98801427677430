import React from "react";

export default function MiniCard({ title, coloredCard, id }) {
  return (
    <div className="minicard">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minWidth: "fit-content",
          maxWidth: "100px",
          minHeight: "max-content",
          background: coloredCard === id ? "#101010" : "#f5f5f5",
          color: coloredCard === id ? "#f5f5f5" : "#101010",
          padding: "10px 20px",
          margin: "10px",
          borderRadius: "50px",
          border: "1px solid #101010",
          cursor: "pointer",
        }}
      >
        {title}
      </div>
    </div>
  );
}
