import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import EmailButton from "../../components/Button/EmailButton/EmailButton";

import "./full.css";

export default function Full() {
  return (
    <div className="fullPage">
      <Navbar></Navbar>
      <div className="fullBg">
        <h1>
          Al momento, il volume degli ordini ci impedisce di accettarne
          ulteriori
        </h1>
        <p>
          Se desideri essere avvisato quando saremo in grado di accettare nuovi
          ordini, mandaci un email:
        </p>
        <EmailButton text={"Apri Email"}></EmailButton>
      </div>
      <Footer></Footer>
    </div>
  );
}
