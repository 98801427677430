import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Servizi from "./pages/Servizi/Servizi";
import Info from "./pages/Info/Info";
import Assistenza from "./pages/Assistenza/Assistenza";
import Inizia from "./pages/Inizia/Inizia";

import website from "./assets/svg/servizi/website.svg";
import ecommerce from "./assets/svg/servizi/ecommerce.svg";
import mobile from "./assets/svg/servizi/mobile.svg";
import pcprogram from "./assets/svg/servizi/pcprogram.svg";
import webapp from "./assets/svg/servizi/webapp.svg";
import AreaRiservata from "./pages/AreaRiservata/AreaRiservata";
import Full from "./pages/Full/Full";

function App() {
  const servizi = [
    {
      id: 0,
      title: "Sito Web",
      text: "Costruiamo siti web ottimizzati e personalizzati per dare voce al tuo brand o progetto.",
      time: "2-3 settimane",
      caratteristiche: [
        "Design adattivo per tutti i dispositivi",
        "Ottimizzazione per motori di ricerca (SEO)",
        "Caricamento rapido delle pagine",
        "Pannelli di amministrazione facili da usare",
        "Supporto multilingua",
        "Supporto chat in tempo reale",
      ],
      icon: website,
    },
    {
      id: 1,
      title: "E-commerce",
      text: "Sviluppiamo piattaforme e-commerce che mettono in primo piano l'esperienza utente e le conversioni.",
      time: "8-12 settimane",
      caratteristiche: [
        "Carrello della spesa intuitivo",
        "Integrazione con piattaforme di pagamento sicure",
        "Gestione avanzata dell'inventario",
        "Funzionalità di recensione del prodotto",
        "Supporto chat in tempo reale",
      ],
      icon: ecommerce,
    },
    {
      id: 2,
      title: "App mobile",
      text: "Creiamo app mobile che offrono un'esperienza utente superiore",
      time: "6-12 settimane",
      caratteristiche: [
        "Interfaccia utente reattiva",
        "Notifiche push",
        "Interazione fluida e animazioni",
        "Offline mode",
      ],
      icon: mobile,
    },
    {
      id: 3,
      title: "Software PC",
      text: "Sviluppiamo software per PC con un focus su usabilità, sicurezza e funzionalità cross-piattaforma.",
      time: "4-8 settimane",
      caratteristiche: [
        "Interfaccia user-friendly",
        "Supporto per diverse piattaforme (Windows, Mac)",
        "Alta sicurezza e crittografia",
        "Opzioni di personalizzazione avanzate",
      ],
      icon: pcprogram,
    },
    {
      id: 4,
      title: "Webapp",
      text: "Offriamo soluzioni webapp che uniscono potenza e flessibilità, per gestire dati e processi in modo efficiente.",
      time: "2-5 settimane",
      caratteristiche: [
        "Controllo dati e processi",
        "Accesso online da ogni dispositivo",
        "Collaborazione immediata",
        "Crescita e scalabilità",
      ],
      icon: webapp,
    },
  ];

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home servizi={servizi} />} />
        <Route path="/Servizi" element={<Servizi servizi={servizi} />} />
        <Route path="/AreaRiservata" element={<AreaRiservata />} />

        {/* <Route path="/Info" element={<Info />} />
        <Route path="/Assistenza" element={<Assistenza />} /> */}
        {/* <Route path="/Inizia" element={<Inizia />} /> */}
        <Route path="/Inizia" element={<Full />} />
      </Routes>
    </Router>
  );
}

export default App;
