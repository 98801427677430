import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import translateIcon from "../../assets/svg/translateIcon.svg";
import sunIcon from "../../assets/svg/sunIcon.svg";
import moonIcon from "../../assets/svg/moonIcon.svg";
import hamburgerIcon from "../../assets/svg/hamburgerIcon.svg";
import ETISLogo from "../../assets/svg/ETISLogo.svg";

import "./navbar.css";
import RippleButton from "../Button/RippleButton/RippleButton";
export default function Navbar() {
  const [showHamburgerOptions, setShowHamburgerOptions] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [mode, setMode] = useState(null);
  const pages = [
    { label: "Home", path: "/" },
    { label: "Servizi", path: "/Servizi" },
    // { label: "Info", path: "/Info" },
    // { label: "Assistenza", path: "/Assistenza" },
    { label: "Area Riservata", path: "/AreaRiservata" },
    { label: "Inizia", path: "/Inizia" },
  ];
  const currentPath = window.location.pathname;
  const navigate = useNavigate();

  const toggleMode = () => {
    const newMode = !mode;
    setMode(newMode);
    localStorage.setItem("mode", newMode.toString());
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const initialMode = localStorage.getItem("mode");
    if (initialMode === null) {
      // Set to a default value, like 'false'
      setMode(false);
      localStorage.setItem("mode", "false");
    } else {
      setMode(initialMode === "true");
    }
  }, []);
  return (
    <>
      <div className="navbar">
        <img src={ETISLogo} alt="ETIS" className="logo" />
        {windowWidth > 768 ? (
          <>
            <div className="pages">
              {pages.map((page) => (
                <div
                  style={{
                    color: currentPath === page.path ? "#4169E1" : "#333",
                    cursor: "pointer",
                    fontWeight: currentPath === page.path ? "bold" : "normal",
                  }}
                  onClick={() => {
                    navigate(page.path);
                    window.scrollTo(0, 0);
                  }}
                >
                  {page.label === "Inizia" ? (
                    <RippleButton>{page.label}</RippleButton>
                  ) : (
                    page.label
                  )}
                </div>
              ))}
            </div>
            <div className="option">
              <img src={translateIcon} alt="Cambia lingua" />
              {mode === false ? (
                <img
                  src={sunIcon}
                  alt="Switch to Night Mode"
                  onClick={toggleMode}
                />
              ) : (
                <img
                  src={moonIcon}
                  alt="Switch to Light Mode"
                  onClick={toggleMode}
                />
              )}
            </div>
          </>
        ) : (
          <img
            src={hamburgerIcon}
            className="hamburgerIcon"
            alt="Menu"
            onClick={() => setShowHamburgerOptions(!showHamburgerOptions)}
          />
        )}
      </div>
      {showHamburgerOptions && (
        <div
          style={{
            marginTop: "20px",
            position: "fixed",
            top: "0",
            width: "80%",
            height: "fit-content",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-end",
            flexDirection: "column",
            borderRadius: "32px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            padding: "80px 40px 20px 20px",
            boxSizing: "border-box",
            zIndex: "9",
          }}
        >
          {pages.map((page) => (
            <div
              style={{
                color: currentPath === page.path ? "#4169E1" : "#333",
                cursor: "pointer",
                fontWeight: currentPath === page.path ? "bold" : "normal",
                padding: "20px",
              }}
              onClick={() => {
                navigate(page.path);
                window.scrollTo(0, 0);
              }}
            >
              {page.label}
            </div>
          ))}
        </div>
      )}
    </>
  );
}
