import React from "react";
import "./RippleButton.css";

const RippleButton = ({ children, onClick, className }) => {
  const addRippleEffect = (e) => {
    const button = e.currentTarget;
    const ripple = document.createElement("span");
    const rect = button.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);
    const x = e.clientX - rect.left - size / 2;
    const y = e.clientY - rect.top - size / 2;

    ripple.style.width = ripple.style.height = `${size}px`;
    ripple.style.left = `${x}px`;
    ripple.style.top = `${y}px`;

    ripple.classList.add("ripple");

    button.appendChild(ripple);

    ripple.addEventListener("animationend", () => {
      ripple.remove();
    });
  };

  return (
    <button
      className={className ? className : "ripple-button"}
      onClick={(e) => {
        addRippleEffect(e);
        onClick && onClick(e);
      }}
    >
      {children}
    </button>
  );
};

export default RippleButton;
