import React from "react";

import "./sliderdots.css";

export default function SliderDots({ currentIndex, servizi }) {
  return (
    <div className="sliderDots">
      {servizi.map((_, index) => (
        <div
          key={index}
          className="dot"
          style={{
            backgroundColor: currentIndex === index ? "#4169e1" : "#000",
          }}
        />
      ))}
    </div>
  );
}
