import React, { useState } from "react";
import "./emailButton.css";

export default function EmailButton({ text }) {
  const [caricaText, setCaricaText] = useState(text);
  return (
    <div className="emailButton">
      <a
        className="carica"
        // href="mailto:sant22619@gmail.com"
        href={
          "mailto:info@etis-software.it?subject=Interesse per lo Sviluppo di un Nuovo Progetto&body=Gentile team di ETIS Software,%0D%0A%0D%0ASiamo interessati a discutere la possibilità di creare..."
        }
        onClick={() => setCaricaText("Grazie!")}
      >
        {caricaText}
      </a>
    </div>
  );
}
