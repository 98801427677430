import React, { useState, useRef, useEffect } from "react";
import "./servizi.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import MiniCard from "../../components/Card/MiniCard/MiniCard";
import SliderDots from "../../components/SliderDots/SliderDots";

import arrowBack from "../../assets/svg/arrowBack.svg";
import arrowNext from "../../assets/svg/arrowNext.svg";

export default function Servizi({ servizi }) {
  const [coloredCard, setColoredCard] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);
  const titlesRef = useRef(null);

  const findIndexById = (id) => {
    return servizi.findIndex((servizio) => servizio.id === id);
  };

  const handleNext = () => {
    const nextIndex = currentIndex + 1;
    let toTranslate = 0;
    if (nextIndex >= servizi.length) {
      setColoredCard(servizi[0].id);
      setCurrentIndex(0);
      toTranslate = 0;
    } else {
      setColoredCard(servizi[nextIndex].id);
      setCurrentIndex(nextIndex);
      toTranslate = -nextIndex * titlesRef.current.offsetWidth;
    }

    if (titlesRef.current) {
      titlesRef.current.style.transform = `translateX(${toTranslate}px)`;
    }
  };

  const handlePrev = () => {
    const nextIndex = currentIndex - 1;
    let toTranslate = 0;
    if (nextIndex < 0) {
      setColoredCard(servizi[servizi.length - 1].id);
      setCurrentIndex(servizi.length - 1);
      toTranslate = -(servizi.length - 1) * titlesRef.current.offsetWidth;
    } else {
      setColoredCard(servizi[nextIndex].id);
      setCurrentIndex(nextIndex);
      toTranslate = -nextIndex * titlesRef.current.offsetWidth;
    }

    if (titlesRef.current) {
      titlesRef.current.style.transform = `translateX(${toTranslate}px)`;
    }
  };

  useEffect(() => {
    if (sliderRef.current) {
      const toTranslate = -currentIndex * 400; // 300px should match the width set for .slide-item and .serviziRight
      sliderRef.current.style.transform = `translateX(${toTranslate}px)`;
    }
  }, [currentIndex]);

  useEffect(() => {
    if (titlesRef.current) {
      const toTranslate = -currentIndex * 200; // 200px should match the width set for .serviziTitle
      titlesRef.current.style.transform = `translateX(${toTranslate}px)`;
    }
  }, [currentIndex]);

  return (
    <div className="serviziPage">
      <Navbar></Navbar>
      <div className="servizi">
        <div className="serviziMain">
          <h1 className="serviziH1">
            ETIS Software: Trasformiamo le Sfide in Opportunità
          </h1>
          <p>
            Scopri la gamma completa dei nostri servizi software su misura,
            pensati per far crescere la tua azienda.
          </p>
          <div className="serviziMinicards">
            {servizi.map((servizio) => (
              <div
                onClick={() => {
                  const index = findIndexById(servizio.id);
                  setColoredCard(servizio.id);
                  setCurrentIndex(index);
                }}
              >
                <MiniCard
                  title={servizio.title}
                  id={servizio.id}
                  coloredCard={coloredCard}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="serviziInfo">
          <div className="serviziTitle">
            <h1 ref={titlesRef} className="serviziTitles">
              {servizi.map((servizioHeader) => (
                <div
                  style={{
                    color:
                      servizioHeader.id === coloredCard ? "#101010" : "#e0e0e0",
                    minWidth: "max-content",
                    width: "200px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const index = findIndexById(servizioHeader.id);
                    setColoredCard(servizioHeader.id);
                    setCurrentIndex(index);
                  }}
                >
                  {servizioHeader.title}
                </div>
              ))}
            </h1>
          </div>

          <div ref={sliderRef} className="slide-content">
            {servizi.map((servizio) => (
              <div className="slide-item">
                <div className="slideItemTop">
                  <img src={servizio.icon} alt={servizio.title} />
                  <p>{servizio.text}</p>
                </div>
                {servizio.caratteristiche.map((caratteristica) => (
                  <li>{caratteristica}</li>
                ))}
              </div>
            ))}
          </div>
          <div className="sliderArrows">
            <img
              src={arrowBack}
              alt="arrowBack"
              onClick={handlePrev}
              className="arrow"
            />
            <SliderDots currentIndex={currentIndex} servizi={servizi} />
            <img
              src={arrowNext}
              alt="arrowNext"
              onClick={handleNext}
              className="arrow"
            />
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
