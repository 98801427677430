import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import arrowRight from "../../assets/svg/arrowRight.svg";

import "./areariservata.css";

export default function AreaRiservata() {
  return (
    <div className="riservato">
      <Navbar></Navbar>
      <div className="riservatoBg">
        <h1>Area Riservata</h1>
        <input type="email" name="email" id="email" placeholder="Email" />
        <input
          type="password"
          name="password"
          id="password"
          placeholder="Password"
        />
        <div className="riservatoButton">
          <div>Accedi</div>
          <img width={"40px"} height={"30px"} src={arrowRight} alt="Accedi" />
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
