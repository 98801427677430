import React from "react";
import "./Footer.css";
import ETISLogoWhite from "../../assets/svg/ETISLogoWhite.svg";

export default function Footer() {
  return (
    <div className="footerPage">
      <div className="footerInfo">
        <div className="footerLogo">
          <img src={ETISLogoWhite} alt="ETIS" />
        </div>
        <div className="footerItem">
          <h4>Informazioni Aziendali</h4>
          <p>P.Iva: 10350591219</p>
        </div>
        <div className="footerItem">
          <h4>Contatti</h4>
          <p>Info@ETIS-Software.it</p>
        </div>
        <div className="footerItem">
          <h4>Sede legale</h4>
          <p>Via Francesco Petrarca 115bis</p>
        </div>
      </div>
      <div className="dividerFooter"></div>
      <div className="infoLegali">
        Copyright © 2023 etis-software.it - Tutti i diritti riservati.
      </div>
    </div>
  );
}
